
  export default {
    components: {
      ApplyOnJobPostingDialog: () =>
        import('@/components/candidate/ApplyOnJobPostingDialog'),
      RegistrationOrLoginDialog: () =>
        import('@/components/candidate/RegistrationOrLoginDialog'),
      RegistrationAndApplicationDialog: () =>
        import('@/components/candidate/RegistrationAndApplicationDialog'),
    },
    props: {
      job: {
        type: Object,
        required: true,
      },
      application: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      buttonClass: {
        type: String,
        required: false,
        default: '',
      },
      textButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      blockButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      loginButtonText: {
        type: String,
        default: 'job_card.apply_now',
      },
      topJob: {
        type: Boolean,
        default: false,
      },
      comeFrom: {
        type: String,
        required: false,
        default: 'detailPage',
      },
      applicationDialog: {
        type: Boolean,
        required: false,
        default: false,
      },
      width: {
        type: String,
        required: false,
        default: 'auto',
      },
    },
  };
